
<template>
  <v-container>
    <v-tabs v-model="tab" grow show-arrows slider-color="#E9282A" >
      <v-tab v-for="(tab, value) in tabs" :value="value" :disabled="tab.disabled" nuxt="true"
        :to="`/dashboard/${value}`"
        :class="{ 'selected-tab': tabIsSelected(value), 'unselected-tab': !tabIsSelected(value) }"
        >
        {{ tab.title }}
      </v-tab>
    </v-tabs>

    <slot />
  </v-container>
</template>

<script setup lang="ts">
const tabs: { [key: string]: { title: string; disabled: boolean } } = {
  overall: { title: "Overall", disabled: false },
  eia: { title: "Environmental", disabled: false },
  ems: { title: "EMS", disabled: false },
  assets: { title: "Assets", disabled: false },
  hcm: { title: "HCM", disabled: true },
  mfg: { title: "MFG", disabled: true },
};

const route = useRoute();
const tab = ref(route.path);
const tabIsSelected = (value: string) => {
    return tab.value === value;
  };
  
</script>
<style scoped>

.v-tabs,
.v-tab {
  font-family: 'Nunito Sans', sans-serif;
}

.selected-tab {
  color: #001a21 !important;
  font-weight: 700 !important;
}

.unselected-tab {
  color: #808b95;
}
</style>
